<template>
	<div>
	</div>
</template>

<script>
	export default{
		name: 'UserAlram'
		,props: ['user', 'callBack']
		,created: function(){
		}
		,data: function(){
			return {
				program: {
					title: '알림'
				}
				,items: []
			}
		}
		,methods: {
			getData: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: ''
				})
			}
		}
	}
</script>